.first-item-container {
  display: flex;
  gap: 200px;
  align-items: center;
}
.book-total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-btn-primary:not(:disabled):hover {
  color: #fff;
  background-color: #4096ff;
}
.authors-cards {
  // display: grid;
  // grid: auto / auto auto auto;
  display: flex;
  flex-wrap: wrap;
}
.mainContainer {
  .card-container {
    text-transform: capitalize;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: auto;
    text-align: center;
    border-radius: 10px;
    width: 182px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    cursor: pointer;
    img.card-image {
      width: 100%;
      height: 140px;
    }
  }
  .authorName {
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
  }
}
//viewauthobookcard
.author_books_container {
  .authorName {
    font-size: 17px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 5px;
  }

  .card_flex {
    display: flex;
    flex-direction: wrap;
  }
}
.ant-btn-primary {
  color: #fff;
  background-color: #172b4d;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  // margin: 20px 0px 10px 10px;
}
.books {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 0px;
}

.second-item-container {
  margin-top: 0px;
  // width: 820px;
}

.tab {
  display: flex;
  flex-direction: row;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
}

.tab-number-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  background-color: #172b4d;
  color: #ffffff;
  margin-left: 8px;
}

.bg-green {
  background-color: #55b035;
}

.bg-red {
  background-color: #172b4d;
}
.bg-brown {
  background-color: var(--brown);
}
.bg-neun {
  background-color: var(--neon-blue);
}

.drop-downs-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 70px;
  left: 63%;
}

.genre {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #000000;
  margin-left: 16px;
}
.books-count-container {
  background-color: #172b4d;
  width: 43.75px;
  height: 43.75px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
}
.books-count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
}
/*input-search styles*/
.ant-input-group .ant-input {
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  color: #8e8e8e;
  outline: none;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border: none;
  width: 100px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: white;
}

.anticon-search {
  width: 30px;
  height: 35px;
  padding-top: 5px;
  background-color: #172b4d;
  color: #ffffff;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  border: none;
}
