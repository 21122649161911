.leaves-container {
  // margin-top: 50px;
  position: relative;
  text-transform: capitalize;
  .apply-btn {
    background-color: #172b4d;
    color: #fff;
    // position: absolute;
    // top: -35px;
    // right: 15px;
    margin-bottom: 10px;
  }
  .cancel-btn {
    background-color: #fff;
    color: #172b4d;
  }
  .modal-text {
    font-size: 20px;
    font-weight: 800;
    text-transform: capitalize;
  }
  .full-details-button {
    padding: 0px 10px !important;
    // background-color: #172b4d;
    // color: #fff;
    background: none;
    &:hover {
      background: none;
      box-shadow: none;
    }
  }
  .disabled {
    background-color: #21385f;
  }
}
