.book-main-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 7px;
  // justify-content: space-between;
}

/* Style for individual card */
.books-row,
.card-container {
  width: calc(25% - 10px);
  margin-bottom: 20px;
  box-sizing: border-box;
}
.ant-btn-primary:not(:disabled):hover {
  color: #fff;
  background-color: #4096ff;
}
.books {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 0px;
}
.card-container {
  text-transform: capitalize;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 10px;
  width: 181px;
  padding: 10px;
  margin-right: 20px;
  margin-top: 10px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  cursor: pointer;
  img.card-image {
    width: 100%;
    height: 140px;
  }
  .card-text {
    text-wrap: wrap;

    p {
      font-size: 16px;
      font-weight: 700;
      text-wrap: wrap !important;
    }
    span {
      font-size: 14px;
      font-weight: 500;
      display: block;
    }
  }
}
.self-container {
  background-color: #172b4d;
  color: #fff;
}
.rent-button {
  color: white;
  text-align: center;
  margin-bottom: 5px;
}
.green-bg {
  background-color: green;
}
.orange-bg {
  background-color: orange;
}
.green-bg:hover {
  background-color: green;
}
.backgroundContainer {
  background: #f0f0f0;
}
.orange-bg:hover {
  background-color: orange;
}
