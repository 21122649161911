.category-container {
    display: flex;
    background-color: white;
    padding: 20px;
    .category-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border: 1px solid #bababa;
      margin-right: 15px;
      padding:  5px 10px;
      border-radius: 6px;
      width: auto;
      h3 {
        font-size: 15px;
        text-align: center;
        padding:0px 5px;
      }
      .img-icon {
        height: 25px;
        width: 25px;
      }
    }
  }