// .rent-cards-header {
//   display: grid;
//   grid: 150px auto auto;
//   overflow-y: scroll;
// }
.conatiner-missing {
  display: flex;
  flex-direction: row;
}
.mainContainer {
  // background: #f0f0f0 !important;
}

.right-side {
  p {
    font-size: 16px;
    font-weight: 700;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    display: block;
  }
}
.common-bg {
  background-color: grey;
}
// .common-conmtainers {
//   display: flex;
//   flex-direction: row;
// }

.rent-card {
  text-transform: capitalize;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 10px;
  width: 191px;
  padding: 10px;
  margin-right: 20px;
  margin-top: 10px;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  cursor: pointer;
  img {
    width: 100%;
    height: 140px;
  }
}
.SuggestButtonContainer {
  position: relative;
  .SuggestButton {
    position: absolute;
    right: 10px;
    top: -50px;
  }
}
.cancel-btn {
  background-color: #ff0000;
  color: #fff;
}
.cancel-btn:hover {
  background-color: #ff0000;
  color: #fff;
}
