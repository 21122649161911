.subjects-grid {
  margin: 10px 10px 0;
}


.subjects-grid-item {
  background: #ffffff;
  border: 2px solid #a8b34b;
  border-radius: 5px;
  display: grid;
  padding: 5px;
  height: 120px;
  grid-template-columns: 100px auto;
}

.subjects-img img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-left: 10px;
}

.subject-rating {
  display: flex;
  justify-content: center;
}

.subject-rating ul {
  display: flex;
}

.sub-feedback {
  display: flex;
  justify-content: end;
}

.sub-feedback small {
  text-decoration: underline;
  font-size: 13px;
  color: var(--red);
}


.subject-textContent {
  flex: 0.6;
  position: relative;
}



.subject-dot {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 30px;
  color: var(--dark-grey);
}

.green {
  color: var(--light-green);
}



.subject-textContent {
  padding-left: 20px;
  cursor: pointer;
}

.subject-textContent .teacherName {
  font-size: 14px;
  font-weight: 600;
}

.subject-textContent span {

  display: block;
  padding: 3px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

/* media queries */

@media (max-width: 1200px) {
  .subjects-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .subjects-grid-item {
    flex-direction: row;
  }

  .subject-dot {
    top: 4%;
  }
}

@media (max-width: 500px) {
  .subjects-grid {
    grid-template-columns: repeat(1, 1fr);
    margin: 20px 15px 0;
  }
}