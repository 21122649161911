.icons-containerFooter {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
}

.copyright {
  text-align: center;
  color: white;
  font-size: 12px;
}
.icons-container {
  display: list-item;
  justify-content: space-around;
  border-color: var(--dark-blue);
  border-width: 2px;
  border-style: solid;
  width: 255px;
  height: 200px;
  padding: 20px 0 0 40px;
  align-items: center;
  justify-content: center;
  .icon {
    margin-right: 10px;
  }
  .icon:hover {
    cursor: pointer;
  }
}

// .footerbg {
//   height: 50px;
//   background-color: black;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px 10px 5px 10px;
// }
.footerbg {
  height: 30px;
  background-color: var(--blue-new);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding: 2px 15px 2px 10px;
  width: 100%;
  z-index: 999;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .footerbg {
    height: 100px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 10px 10px 5px 15px;
    flex-direction: column;
  }
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  .footerbg {
    height: 110px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
}
