.daily-updates-main-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}
.dailyUpdate-datePicker{
    width:200px;
}

.preview-container{
    background-color: white;
    margin: 10px;
    padding: 10px;
    width:45%;
    height:350px;
    overflow: auto;
}