.profile {
  padding: 6px 8px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 97%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-right: 20px;
  margin-top: 55px;
  background-color: rgb(64 191 245);

  ////new dashbord style
  .student-texts {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 5px;
    color: #fff;
  }
  .student-data-title {
    font-size: 14px;
    color: #172b4d;
    font-weight: 800;
    padding-right: 3px;
  }
}
.flex {
  display: flex;
}
.student_details {
  margin-top: 10px;
}
.profile > img {
  width: 20px;
  cursor: pointer;
}
.student-data {
  text-align: center;
  text-transform: capitalize;
  p {
    font-size: 15px;
    font-weight: 600;
  }
  span {
    display: block;
    font-size: 13px;
    font-weight: 500;
  }
}
.dashboard-profile-content {
  display: flex;
  flex-direction: column;
}

.profileactive-img {
  cursor: pointer;
  margin-top: 5px;
}

.sp-sty {
  text-align: center;
  flex: 0.2;
}

.dashboard-exam-desc > div {
  display: flex;
  align-items: center;
}

.dashboard-profile-pic {
  width: 100px;
  height: 100px;
  text-align: center;
  margin: auto;
  align-items: center;
}

.dashboard-exam-desc-img {
  width: 10px;
  right: 10%;
  position: absolute;
}

.dashboard-exam-item {
  position: relative;
}

.attendance-item span {
  background: #ffffff;
  box-shadow: 2px 1px 7px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px 8px;
  font-size: 14px;
}

.attendance-item {
  margin-bottom: 7px;
}
.dashboard-month {
  font-weight: 700;
  padding: 10px 0px;
  span,
  b {
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
  }
}
.dashboard-exam {
  h3 {
    font-weight: bold;
    cursor: pointer;
  }
  span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
  }
}
.month-attendace {
  display: flex;
}

.attendance-span {
  margin-right: 10px;
  flex: 0.8;
  text-align: center;
  font-size: 13;
}

.dashboard-profileItem {
  margin-bottom: 30px;
}

.dashboard-profileItem p {
  font-size: 12px;
  margin-top: 6px;
}

.profile-active {
  margin-top: 64px;
  width: 70%;
  margin-right: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1025px) {
  // .profile {
  //   width: 70%;
  // }
}

@media (max-width: 768px) {
  .profile-active {
    width: 100%;
    margin-top: 10px;
  }

  .profile {
    width: 100%;
    margin-top: 20px;
  }
}
