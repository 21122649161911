.rent-cards-header {
  // display: grid;
  // grid: auto / auto auto auto;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  align-items: center;
  .card-container {
    text-transform: capitalize;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: auto;
    margin-bottom: 15px;
    text-align: center;
    border-radius: 10px;
    width: 191px;
    padding: 10px;
    margin-right: 20px;
    margin-top: 10px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    cursor: pointer;
    img {
      width: 100%;
      height: 140px;
    }
  }

  .miss-book {
    background-color: #ff0000;
    color: #fff;
  }
}
