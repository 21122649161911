.assignment-flexitem {
  display: flex;
  margin: 50px 30px 20px 20px;
}

.upcoming-assignment {
  background-color: yellow;
  /* You can choose any background color you like */
}

.assignment-languages {
  flex: 0.3;
  margin-top: 16px;
}

.uploadDoc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.assignment-duties {
  flex: 0.7;
  margin-left: 10px;
}

.assignment-languages button {
  margin: 5px 0 10px;
}

.assign-btnsSty {
  padding: 10px 0;
  background: #d2cfcf;
  border-radius: 5px;
  border: none;
  color: #bbb8b8;
  font-weight: 500;
  font-size: 19px;
  width: auto;
}

.assignment-languages button:nth-child(1) {
  background: #ffffff;
  color: black;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.assignment-duty {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  width: 80%;
  margin-top: 5px;
}

.assignment-duty h3 {
  padding-left: 30px;
}

@media (max-width: 1150px) {
  .assignment-flexitem {
    margin: 50px 10px 20px;
    flex-direction: column;
  }

  .assignment-duty {
    width: 100%;
  }
}