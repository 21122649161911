tbody tr .holiday-data {
  background-color: transparent !important;
  padding: 0px !important;
  margin: 0 !important;
}

.holidaysMainbg {
  padding: 5px;
  position: relative;
  top: 0;
}

.gridcards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

tbody tr td > .attendance-box {
  padding: 20px 10px;
  margin: 5px 10px;
}

.holiday-header th {
  border-bottom: none !important;
  font-size: 13px;
  padding-bottom: 0px;
}

.attend-check {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.attend-check small {
  font-size: 9px;
  color: #096cc8;
}

.span-text {
  font-size: 10px;
  color: white;
  border-radius: 10px;
  padding: 3px 7px;
}

.attend-icon {
  margin-right: 5px;
}

.disabled {
  opacity: 0.6;
}

.wholeContainer {
  background-color: white;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  margin-top: 10px;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 60px;
  overflow-y: auto;
  color: white;
  line-height: 1.5715;
  text-align: left;
}

.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 0px 0;
  margin-top: 5px;
}

.ant-btn:hover,
.ant-btn:focus {
  color: white;
  border-color: #172b4d;
  background: #172b4d;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  /* z-index: 1; */
  color: white;
  background: #172b4d;
  border-color: #172b4d;
}

.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: #172b4d;
}

.ant-radio-button-wrapper-checked {
  background: #172b4d !important;
  color: white;
}

.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #ffffff;
  border-color: #172b4d;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: #172b4d;
}

.my-table {
  margin: 10px;
}

.my-table th,
.my-table td {
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.my-table th {
  background-color: #f90202;
  font-weight: bold;
}

.holiday-btns {
  /* text-align: start;
  position: absolute;
  left: 0; */
  /* padding: 10px 30px; */
  justify-content: space-between;
}

.radioparent {
  border-bottom: 1px dashed var(--border);
  margin-bottom: 10px;
  .mainradio {
    .ant-radio-button-wrapper {
      background: var(--white-font);
      border: none !important;
      border-radius: 5px 5px 0 0;
      margin: 0 3px 0 0;
      &::before {
        border: none !important;
        width: 0;
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: var(--blue-new);
      border: none !important;
      border-radius: 5px 5px 0 0 !important;
    }
  }
}

.holidayCard {
  border-radius: 10px;
  width: 23.5%;
  height: auto;
  margin: 0;
  background: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h5 {
    font-weight: 100;
    text-transform: capitalize;
  }
}

.CreateButton {
  background-color: #172b4d;
  color: white;
  border-radius: 5px;
}

.loadingHolidaysCss {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingHolidays {
  height: 700px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingCallenderCss {
  height: 790px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolTip {
  background-color: #050505;
  color: #ffffff;
  padding: 10px;
}

.rightbtn {
  text-align: right;
}

.cancel-button {
  background-color: var(--dark-blue);
  text-align: center;
  width: 100px;
  color: #fff;
  border-radius: 5px;
  border: none;
}

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//   height: 35px;
//   width: 120px;
// }

.ant-form-item {
  margin-bottom: 10px;
}

.submit {
  background-color: var(--blood-red);
  text-align: center;
  width: 100px;
  color: #fff;
  border-radius: 5px;
  border: none;
}

.ant-picker {
  width: 100%;
}

.tabholidayview {
  /* display: flex; */
  /* padding: 4px; */
  gap: 10px;
  /* margin-top: 50px; */
}

.holidayNumber {
  /* position: absolute;
  top: 15px;
  right: 30px;
  font-size: 17px;
  font-weight: 700; */
  margin-bottom: 10px;
}

.holidayNumbers {
  font-size: 17px;
  font-weight: 700;
  margin: 0 0 15px 0;
}

.holidayviewlft {
  /* background: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  top: 0; */
  /* height: 767px; */
}

.holidayviewrft {
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.holidadyleft {
  height: auto;
  border-radius: 20px;
  /* padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); */
}
.calender-texts {
  text-align: center;
  text-transform: capitalize;
  background-color: rgb(255, 167, 3);
  border-radius: 2px;
  span {
    display: block;
    font-size: 12px;
  }
  .type {
    font-size: 15px;
  }
}
@media (max-width: 650px) {
  .rightbtn {
    text-align: left;
    margin: 10px 0;
  }

  .tabholidayview {
    display: flex;
    padding: 4px;
    flex-direction: column;
  }

  .holidadyleft {
    height: auto;
    overflow: auto;
  }

  .holidayCard {
    width: auto;
  }

  .ant-radio-group {
    width: 100%;
  }

  .RadioButtons {
    width: 50%;
  }
}
