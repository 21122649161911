.heading {
  background-color: #E5E5E5;
}

h4 {
  /* padding: 10px 0 10px 5px; */
}

.main-bg {
  /* background: #f0f0f0;
  border-radius: 10px;
  padding: 5px; */
}

/* .ant-table-wrapper tfoot>tr>td {
  position: relative;
  padding: 9px 9px;
  overflow-wrap: break-word;
}
:where(.css-dev-only-do-not-override-acm2ia).ant-table-wrapper table {
  width: 100%;
  text-align: start;
  border-radius: 8px 8px 0 0;
  border-collapse: separate;
  border-spacing: 0px 6px;
}

.ant-table-wrapper .ant-table-tbody>tr>td
.ant-table-wrapper tfoot>tr>th
.ant-table-wrapper tfoot>tr>td {
  position: relative;
  padding: 0px 1px;
  overflow-wrap: break-word;
} */

/* 
.ant-table table{
  background: #FFFFFF;
  border-spacing: 0 5px;
} */