/* schoolname styles */
.dashboard-flex,
.dashboard-table {
  justify-content: space-between;
  display: flex;
  justify-content: space-between;
}

.latest-activicty {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  justify-content: space-between;
}

.dash-main {
  display: flex;
  gap: 10px;

}

.dash-col {
  width: 50%;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: var(--radius10);
  padding: var(--padding10);
  box-shadow: var(--shadow);
}

.dash-col h2 {
  font-size: 18px;
  margin-bottom: 15px;
}


.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;

  background: transparent;
  border-radius: 8px;
}

.activity-item {
  padding: 3px 5px;
  margin-bottom: 5px;
  color: #000;
  font-weight: 600;
  border-radius: 11px;
  font-size: 14px;
}

.attendance-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-exam h3 {
  text-align: center;
}

.dashboard-exam-item {
  border-radius: 10px;
  display: flex;
  padding: 10px 6px;
}

.dashboard-img-items {
  justify-content: none;
  right: 0;
  position: absolute;
}

.dashboard-img-items img {
  margin-right: 10px;
}

.dashboard-flex h1 {
  font-weight: 400;
  font-style: normal;
}

.dashboard-flex,
.dashboard-img-items {
  padding: 0px 2px;
  margin-right: 15px;
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 5px;
}

.dashboard-table-items {
  background: var(--green);
  padding: 7px 5px;
  color: white;
  border-bottom-left-radius: 16px;
  border-top-right-radius: 16px;
}

.dashboard-table-items img {
  width: 30px;
}

.dashboard-grid {

  /* padding: 5px 0px 0px 22px; */
  max-height: 100px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dashboard-textContent {
  padding: 0px 7px;

}

.dashboard-textContent p {
  font-size: 12px;
  font-weight: 600;
  display: flex;
  text-transform: capitalize;
  font-weight: 600;
  cursor: pointer;
}

.dashboard-textContent .period {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

/* 
.anticon {
  color: rgb(252, 252, 33)
} */

.dashboard-card .subjecticons {
  padding-top: 5px;
  margin-right: 10px;
  font-size: 25px !important;
  font-weight: 600;

}

.dashboard-activity {
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 5px;
}

.dashboard-grid-item1 {
  margin: 0 10px;
  height: 'auto';
  width: 100%;
  border-radius: 5px;
}

.ant-card .ant-card-body {
  padding: 3px;
  border-radius: 0 0 8px 8px;
}

.dashboard-activity p {
  font-size: 14px;
}

.activity {
  margin: 8px 0 4px;
}

.dashboard-flex {
  padding-top: 5px;
}

.dashboard-table h2 {
  margin-left: 10px;
  font-weight: 600;
}

.accol {
  font-size: 14px;
  margin: 0 0 10px 0;
}

.acdate {
  padding: 3px 12px;
  display: inline-block;
  margin: 0 6px 0 0;
  border-radius: 5px;
}

.colr1 {
  background-color: var(--dsh-blue-light);
}

.colr2 {
  background-color: var(--dsh-brown-light);
}

.colr3 {
  background-color: var(--dsh-purple-light);
}

.colr4 {
  background-color: var(--dsh-brown-light);
}

.colr5 {
  background-color: var(--dsh-pink-light);
}

.tablediv {}

.tablerow {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  border-bottom: 1px dashed #ccc;
}

.theadm {
  background: var(--grey);
}

.cltd {
  width: 25%;
  padding: 10px 5px;
}

.cltd:nth-child(1) {
  width: 20%;

}

.cltd:nth-child(2) {
  width: 10%;

}

.cltd:nth-child(3) {
  width: 35%;

}

.cltd:nth-child(4) {
  width: 15%;

}



/* media queries */
@media (max-width: 1200px) {
  .dashboard-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .main-bg {
    padding: 5px 6px;
  }
}

@media (max-width: 768px) {
  .dashboard-flex h1 {
    font-size: 23px;
  }

  .dashboard-img-items img {
    width: 20px;
  }

  .dashboard-flex {
    padding-top: 70px;
  }

  .dashboard-table-items span {
    font-size: 12px;
  }

  .dashboard-grid {
    grid-template-columns: auto auto auto;
    padding: 20px;
    text-align: center;
  }
}

/* //dashboard-updated_grid */
.dashboard-updated_grid {
  display: grid;
  grid-template-columns: 20rem auto auto;
  gap: 5px;
}

.attendace-card {
  display: flex;
  justify-content: space-between;
  padding-left: 5px;
}

.bar {
  display: flex;
  flex-direction: column;
}

.common-bgs {
  overflow-y: scroll;
  /* background-color: rgb(159, 222, 249);
  border-radius: 10px; */
  cursor: pointer;
  flex: 1;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  padding: 5px;
  text-transform: capitalize;
  max-height: 170px;
  /* padding-left: 20px; */
}

.overall-Grade {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.position {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  display: flex;
  gap: 15px;
  justify-content: center;

}

.grade-icon {
  height: 35px;
  width: 60px;
}

.grade {
  /* background-color: rgb(159, 222, 249); */
  border-radius: 5px;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  padding: 5px;
  text-transform: capitalize;
  margin: 0;
  align-items: center;
  text-align: center;

}

.overall-Grade {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}

.syllabus-content span {
  padding-left: 10px;
}

h5 {
  font-size: 14px;
  font-weight: bold;
  text-align: start;
  /* margin-bottom: 5px; */
}

.atednace-content p {
  font-size: 13px;
  font-weight: 500;
  display: block;
  margin-top: 10px;
}

.exam-container {
  position: relative;
  top: 0;

}

.ant-progress-bg {
  height: 13px;
}

.exam-container .exam-cards {
  background-color: var(--dsh-blue-light);
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px;
  text-align: center;
  font-size: 10px;
  text-align: start;
  margin-bottom: 5px;
}

.examimg {
  /* font-size: 30px;
  position: absolute;
  right: 35px;
  top: 40px; */
}

.atednace-chat {
  margin-top: -25px;
  margin-right: -5px;
}



.dashboard-card.completed-period {
  background-color: #172b4d;
  color: white;
}

.dashboard-card.upcoming-period {
  background-color: rgb(159, 222, 249);
  color: #000;
}

.dashboard-card.ongoing-period {
  background-color: orange;

}

.roundbtn1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #172b4d;
}

.roundbtn1 strong {
  font-size: 10px;
}

.roundbtn2 strong {
  font-size: 10px;
}

.roundbtn2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(159, 222, 249);

}

.exam-card {
  display: flex;
  gap: 10;
}

.exam-card p {
  width: 25%;
  word-break: break-all;
}


.syllabus.syllabus-text, .grade-text {
  align-items: center;
  padding: 5px;
  text-align: center;
}

.grade-text span {
  font-size: 16px;
  font-weight: 600;
}

.syllabus .syllabus-text p {
  font-size: 12px;
  font-weight: 500;
}

.syllabus-text span {
  font-size: 20px;
  font-weight: 700;
}

.subject-logo {
  height: 30px;
  width: 50px;
  padding-top: 5px;
}


.dashboard-exam-container {
  /* margin-top: 5px; */
}

@media (max-width: 500px) {
  .dashboard-table {
    flex-direction: column;
  }

  .dashboard-table h2 {
    font-size: 18px;
    margin: 10px 0;
  }

  .dashboard-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .dashboard-grid-item {
    margin: 0 10px;
    height: 'auto';
    width: 'auto';
  }

  .dashboard-flex h1 {
    font-size: 16px;
  }
}