.books-container {
  display: flex;
  width: 830px;
  .icon-container {
    text-align: center;
    .stars {
      display: inline-block;
      width: 16px;
      height: 16px;
      background-color: var(--yellow);
      clip-path: polygon(
        50% 0%,
        63% 38%,
        100% 38%,
        69% 59%,
        82% 100%,
        50% 75%,
        18% 100%,
        31% 59%,
        0% 38%,
        37% 38%
      );
    }
  }
}
.mainContainer {
  // padding: 6px;
  // background: #f0f0f0;
  // margin-top: 0px;s
}
.horizontal-scroll-container {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent content from wrapping to a new line */
  scrollbar-width: thin;
  /* Set the width of the scroll bar */
  scrollbar-color: #888888 #dddddd;
  /* Set the color of the scroll bar */
}

/* For Webkit browsers (Chrome, Safari) */
.horizontal-scroll-container::-webkit-scrollbar {
  width: 1px;
  /* Adjust the width as per your preference */
}

.horizontal-scroll-container::-webkit-scrollbar-track {
  background-color: transparent;
  /* To make it transparent */
}

.horizontal-scroll-container::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Adjust the color as per your preference */
  border-radius: 5px;
  /* Adjust the radius as per your preference */
}

.author_main_container {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-height: 300px;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 10px;
  width: 185px;
  margin-right: 20px;
  padding: 5px;

  .card-image {
    width: 100%;
    height: 150px;
  }
  .nameWidth {
    font-size: 16px;
    font-weight: 700;
    text-wrap: wrap;
    width: 170px;
  }

  .author-card-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 35px;
  }
}
.card-container {
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 10px;
  width: 185px;
  margin-right: 20px;
  padding: 5px;

  .card-image {
    width: 100%;
    height: 150px;
  }
  .nameWidth {
    font-size: 16px;
    font-weight: 700;
    text-wrap: wrap;
    width: 170px;
  }

  .author-card-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100px;
  }
}
