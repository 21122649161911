.transport-wrapper {
  .pickuppointsCard {
    background-color: white;
    border-radius: 10px;
    height: auto;
    width: auto;
    display: flex;
    box-shadow: 0 12px 24px 0 rgba(13, 13, 13, 0.06);
    flex-direction: row;
    margin: 10px 0 10px 0;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px;
  }
  .containers {
    margin: 15px 0;
  }
  .inputbar {
    width: 500px;
  }
  .ant-card-body {
    padding: 0px 20px;
    border-radius: 0 0 8px 8px;
    text-transform: capitalize;
  }
  .headings {
    // text-align: start;
    // margin: 10px 0;
    font-size: 18px;
  }
  .driverDeatils {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .driverDetailsCard {
    width: auto;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background: var(--dsh-pink-light);

    .dr_name {
      font-weight: 700;
    }
  }
  .transport-card {
    // color: #fff;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--dsh-pink-light);
    padding: 10px;
    width: 200px;
    .name {
      font-size: 14px;
      font-weight: 800;
    }
    .time {
      font-size: 13px;
      font-weight: 700;
    }
    span {
      display: block;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .horizontal-line {
    margin-left: 10px;
    height: 2px;
    width: 50px;
    background-color: #000;
    margin-top: 10px;
  }
  .save-button {
    float: right;
    margin-top: 20px;
  }
  .school-imgage {
    width: 70px;
    height: 70px;
  }

  .transport-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .route-container {
    background-color: #fff;
    padding: 3px;
    text-align: center;
    border-radius: 6px;
    h1 {
      margin-bottom: 10px;
    }
  }
  .scrolling-text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }

  .scrolling-text h2 {
    animation: scroll 3s linear infinite;
  }
  :where(.css-dev-only-do-not-override-6j9yrn).ant-form-item
    .ant-form-item-label
    > label {
    height: 40px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 35px;
    width: 260px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(
        -100%
      ); /* End with the text off-screen to the left */
    }
  }
}
