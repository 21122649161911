.bars {
  display: none;
}


.sidebar {
  height: calc(100vh - 90px);
  overflow-y: scroll;
}

/* Thin scrollbar */
.sidebar::-webkit-scrollbar {
  /* margin-left: 10px; */
  width: 5px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1;

}

/* Thumb */
.sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Thumb on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Thin scrollbar for Firefox */
.sidebar {
  scrollbar-width: none;
  scrollbar-color: #888 #f1f1f1;
}

/* Track for Firefox */
.sidebar::-moz-scrollbar-track {
  background-color: #f1f1f1;
}

/* Thumb for Firefox */
.sidebar::-moz-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Thumb on hover for Firefox */
.sidebar::-moz-scrollbar-thumb:hover {
  background-color: #555;
}

.sidebar {
  background: var(--dark-blue);
  /* padding: 0 5px; */
  height: 100%;
  position: sticky;
  z-index: 9;
  top: 0;
  left: 0;
  right: 5px !important;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  overflow-y: scroll;
}

.sidebar h5 {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  margin-top: 13px;
}

.sidebar-grid {
  gap: 5px;
  /* margin-top: 20px; */
  margin-bottom: 50px;
}

.sidebar-item {
  text-align: center;
  cursor: pointer;
}

.sidebar-img-active {
  .sidebar-item {
    color: var(--white-font);
    /* padding: 10px !important; */
    margin: 10px 0;
    width: 100%;
    border-radius: 0;
  }
}


.sidebar-img {
  /* border-radius: 5px;
  height: 45px; */
  /* background: #f1f1f1;
  opacity: 0.3;
  border-radius: 3px; */
}

.sidebar-img .item-icon {
  /* padding: 2px 5px 0px 5px; */
  /* background: #f1f1f1; */
  opacity: 0.3;
  /* border-radius: 3px; */
  
}

.sidebar-img .item-icon,
.sidebar-img-active .item-icon {
  font-size: 24px;
  /* color: var(--dark-blue); */
  color: var(--white-font);
  opacity: 1;

}
.sidecol{
  display: flex;
  flex-direction: column;
  padding:5px 0;
}

.sidebar-img-active {
  background: var(--hover-blue);
  /* border-radius: 5px; */
  /* height: 25px; */
  
}

.item-icon-active {
  color: white;
  font-size: 24px;
  /* margin-top: 8px; */
}

.sidebar-item p {
  font-size: 12px;
  /* padding-top: 5px; */
  /* margin-bottom: 10px; */
  color: var(--white-font);
}

/* Tablets */

@media screen and (max-width: 768px) {
  .bars {
    display: block;
    position: fixed;
    top: 3rem;
    left: 0;
    z-index: 9;
    right: 0px;
    /* border-bottom: 1px solid #222222; */
  }

  .sidebar-icon {
    font-size: 20px;
    margin-bottom: 7px;
  }

  .sidebar {
    position: fixed;
    z-index: 999;
    width: 60%;
    height: 100%;
    margin-top: 40px;
  }
}