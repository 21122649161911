.cardBg {
  height: 2500px;
  width: 240px;
  margin: 20px;
}

.h1Container {
  height: 250px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.noExams {
  text-align: center;

}

.noExams span {
  text-align: center;
  color: red;
}

.results-title {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
}

.radiobtn {
  width: 200px;
  text-align: center;
  border-radius: 10px;
}


.results-widget {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.gridPlace {
  /* display: flex; */
  /* width: 50%; */
  margin: 0px 50px 0 40px;
}

.exam-btns {}

.tabs-btn {
  padding: 0.7rem 2rem;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 8px;
  border: 0;
  background: #d2cfcf;
  color: #888686;
}

.tabs-btn-active {
  background: var(--dark-blue);
  color: white;
}

.tabs__flex {
  margin-top: 10px;
}

/* upcoming styles */
.upcoming {
  display: flex;
  margin: 50px 30px 0 30px;
}

.upcoming-btns {
  flex: 0.3;
}

.upcoming-btns button {
  margin: 0px 20px 10px 0;
}

.upcoming-btns button:nth-child(1) {
  background: #ffffff;
  color: black;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.upcoming-grid {
  flex: 0.7;
  gap: 20px;
}

/* present styles */

.present-grid {
  margin: 0px 50px 0 40px;
}

.present-img {
  width: 40px;
  margin-right: 10px;
}

.present-grid-item>p {
  text-align: center;
  font-weight: 500;
  padding: 15px 0;
  width: 250px;
  border-radius: 5px;
}

.present-single-item {
  background: #b8fed4;
  border-radius: 5px;
  text-align: center;
  text-transform: capitalize;
  padding-left: 70px;
  padding-top: 5px;
  padding-bottom: 5px;

}

.present-desc p {
  font-size: 12px;
}

/* previous styles */
.prev-exams-grid {
  flex: 0.7;
  gap: 10px;
}

/* media queries */

@media (max-width: 500px) {

  .exam-btns,
  .upcoming {
    flex-direction: column;
  }

  .tabs-btn {
    font-size: 14px;
    margin: 0px 0px 10px;
    width: 100%;
  }

  .present-grid {
    grid-template-columns: repeat(1, 1fr);
    margin: 0px;
  }

  .upcoming-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .upcoming-btns button {
    margin: 0px 0px 10px 0px;
  }
}

:where(.css-dev-only-do-not-override-acm2ia).ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
:where(.css-dev-only-do-not-override-acm2ia).ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
:where(.css-dev-only-do-not-override-acm2ia).ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab,
:where(.css-dev-only-do-not-override-acm2ia).ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
  padding: 8px 24px;
  text-align: center;
  color: #172b4d;
}

/* Add the CSS style for the active tab */
.ant-tabs-tab-active .ant-tabs-tab {
  background-color: blue;
  /* Add any other styles you want for the active tab */
}