.attendace-wrapper {
  .bg {
    color: red;
    background-color: red;
  }

  .attendance-desc {
    font-weight: 400;
    font-size: 15px;
    background-color: #172b4d;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
  }

  .attendane-grid {
    // border: 2px solid #d3d0d0;
    // border-radius: 20px;
    grid-template-columns: repeat(5, 1fr);
    // margin: 5px 20px;
    gap: 15px;
    margin: 0 0 15px 0;
  }

  .attendance-content-item {
    padding: 5px 20px;
    background: #fff;
    border-radius: var(--radius10);
  }
  .custom-calendar {
    // margin-top: 20px;
    border-radius: 10px;
  }
  /* .custom-calendar .ant-radio-group {
  display: none;
} */

  /* .ant-select .ant-picker-calendar-year-select {
  display: none !important;

} */
  .custom-calendar .ant-radio-group {
    display: none;
  }

  .custom-calendar .ant-picker-calendar-year-select {
    display: none !important;
  }

  /* .custom-calendar .ant-picker-calendar-month-select {
  display: none !important;
} */

  .ant-picker-calendar-header {
    padding: 10px;
    margin-right: 5px;
  }

  .ant-picker-calendar-year-select {
    display: none;
  }

  .attendance-circle {
    // height: 45px;
    // width: 45px;
    background: #ffffff;
    // box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    font-weight: 600;
    border-radius: 100%;
    font-size: 26px;
  }

  .attendance-content-item small {
    font-size: 14px;
    font-weight: 400;
  }

  .rate-circle {
    border-radius: 100%;
    height: 15px;
    width: 15px;
    background: red;
  }

  .attendance-rates {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0 0 20px;
  }

  .attendance-rates .rates-item {
    margin: 0 10px 15px 0;
  }

  .headings {
    display: flex;
    justify-content: space-between;
    padding: 0 0 15px 0;
    align-items: center;
    h3 {
      font-size: 18px;
    }
  }

  .att-top-content {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 15px;
    text-align: left;
    justify-content: space-between;

    .iconblock {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
    }
  }
  .col1 {
    background: var(--dsh-blue-light);
  }
  .col2 {
    background: var(--dsh-brown-light);
  }
  .col3 {
    background: var(--dsh-pink-light);
  }
  .col4 {
    background: var(--dsh-purple-light);
  }
  .col5 {
    background: var(--dsh-blue-light);
  }

  .ant-picker-calendar.ant-picker-calendar-full
    .ant-picker-calendar-date-content {
    position: static;
    width: auto;
    height: 30px;
    overflow-y: auto;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.5714285714285714;
    text-align: start;
  }

  .calender-div {
    margin-left: 30px !important;
  }

  .daily-status {
    padding: 2px;
    color: white;
    text-align: center;
    border-radius: 10px;
  }

  .ant-radio-button-wrapper-checked:not(
      :where(
          .css-dev-only-do-not-override-acm2ia
        ).ant-radio-button-wrapper-disabled
    ) {
    /* z-index: 1; */
    color: #fefefe;
    background: #172b4d;
    border-color: #172b4d;
  }

  .ant-radio-button-wrapper-checked:not(
      :where(
          .css-dev-only-do-not-override-acm2ia
        ).ant-radio-button-wrapper-disabled
    ):hover {
    color: white;
    border-color: #172b4d;
  }

  .rates-item small {
    font-size: 11px;
    padding-left: 10px;
    font-weight: 800;
  }

  .calnederContainer {
    position: relative;
    top: 0;
  }

  .attendance-rates {
    position: absolute;
    //top: 106px;
  }

  /* media queries */

  @media (max-width: 960px) {
    .attendane-grid {
      grid-template-columns: repeat(3, 1fr);
    }

    .attendance-content-item small {
      font-size: 12px;
    }
  }

  @media (max-width: 500px) {
    .attendane-grid {
      grid-template-columns: repeat(1, 1fr);
      margin: 5px 0px;
    }
  }
}
