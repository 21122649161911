.edpedia-store {
  background-color: #f2f2f2;
  height: 100vh;
  overflow-y: scroll;
  margin-top: 50px;
  .header-title {
    text-align: center;
    background-color: black;
    padding: 3px 0px;
    h1 {
      color: white;
      font-size: 17px;
    }
    span {
      color: yellow;
      font-weight: bolder;
      text-decoration: underline;
      margin-left: 30px;
    }
    .closeIcon {
      float: right;
      margin-right: 20px;
      margin-top: 5px;
      color: white;
    }
  }
  .header-container {
    margin: 0px;
    padding: 5px 10px;
    background-color: #e7f5ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .store_search {
      max-width: 400px;
      width: 400px;
    }
    .filter-container {
      display: flex;
      .icon {
        height: 23px;
        margin-right: 3px;
      }
    }
  }

  .dicount-container-main {
    background-color: white;
    margin: 20px 5px;
    padding: 20px;
    border-radius: 6px;
    h1 {
      font-size: 17px;
      margin-bottom: 5px;
    }
    .discount-container {
      display: flex;
      padding: 20px;
    }
    .category {
      display: flex;
      flex-direction: row;
    }
    .category-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // border: 1px solid #bababa;
      margin-right: 15px;
      padding: 10px;
      // border-radius: 6px;
      height: 30px;
      width: 105px;
      h3 {
        font-size: 12px;
        text-align: center;
      }
      .img-icon {
        height: 70px;
        width: 70px;
      }
    }
  }
  .product-view {
    display: flex;
    flex-direction: row;
    // justify-content: space-around;
    padding: 30px;
    .uniform-container {
      background-image: url(../../assets/icons/rectangleBackground.png);
      display: flex;
      gap: 15px;
      .dress {
        height: 300px;
        width: 200px;
      }
    }
    .content-container {
      width: 100%;
      padding: 0px 30px;
      h3 {
        color: #00648f;
        font-weight: bold;
      }
      h4 {
        color: #172b4d;
        span {
          color: #f20000;
          font-weight: bold;
        }
      }
      .buttons-container {
        display: flex;
        gap: 60px;
        .buynow-button {
          background-color: #172b4d;
          color: white;
          height: 50px;
          width: 120px;
          font-size: 18px;
        }
        .add-to-cart-button {
          background-color: #ff9900;
          color: white;
          height: 50px;
          width: 120px;
          font-size: 18px;
        }
      }
    }
  }
}
// / subcategory
.subcategory-container {
  text-transform: capitalize;
  .final-button-div {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
  .card-img {
    width: 240px;
    height: 180px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  :where(.css-dev-only-do-not-override-nnuwmp).ant-col-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 23.6%;
    max-width: 25%;
  }
  .card-text {
    position: relative;
    top: 0;
    text-transform: capitalize;
  }

  .text-name {
    font-weight: 600;
    display: block;
  }
  .name {
    font-size: 17px;
    font-weight: 600;
  }

  .text-percentage {
    /* or any color you prefer */
    float: right;
  }

  .text-price {
    display: block;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: 700;
  }

  .text-item-left {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 60px;
    font-style: italic;
    color: #ff4500;
  }
  .heart-icon {
    float: right;
    font-size: 25px;
    color: rgb(222, 222, 222);
  }
  .heart-icon:hover {
    color: #ff4500;
  }
  .heart-icon:focus {
    color: #ff4500;
  }
  .add-card-button,
  .buynow-button {
    background-color: rgb(255, 169, 10);
    color: #fff;
  }
  .buynow-button:hover {
    background-color: rgb(255, 169, 10);
    color: #fff;
  }
}
.cart-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}

// / subcategory
.subcategory-container {
  text-transform: capitalize;
  .final-button-div {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }
  .card-img {
    width: 240px;
    height: 180px;
    text-align: center;
    display: flex;
    align-items: center;
  }
  :where(.css-dev-only-do-not-override-nnuwmp).ant-col-6 {
    --ant-display: block;
    display: block;
    display: var(--ant-display);
    flex: 0 0 23.6%;
    max-width: 25%;
  }
  .card-text {
    position: relative;
    top: 0;
    text-transform: capitalize;
  }

  .text-name {
    font-weight: 600;
    display: block;
  }
  .name {
    font-size: 17px;
    font-weight: 600;
  }

  .text-percentage {
    /* or any color you prefer */
    float: right;
  }

  .text-price {
    display: block;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: 700;
  }

  .text-item-left {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 60px;
    font-style: italic;
    color: #ff4500;
  }
  .heart-icon {
    float: right;
    font-size: 25px;
    color: rgb(222, 222, 222);
  }
  .heart-icon:hover {
    color: #ff4500;
  }
  .heart-icon:focus {
    color: #ff4500;
  }
  .add-card-button,
  .buynow-button {
    background-color: rgb(255, 169, 10);
    color: #fff;
  }
  .buynow-button:hover {
    background-color: rgb(255, 169, 10);
    color: #fff;
  }
}
.cart-icon {
  width: 20px !important;
  height: 20px !important;
  margin-right: 5px;
}

//popover style
// .ant-popover .ant-popover-inner {
//   background-color: #ffffff;
//   background-clip: padding-box;
//   border-radius: 5px;
//   box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//     0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
//   padding: 0px;
//   width: 400px;
//   height: 200px;
//   padding: 10px;
// }

.ant-popover .ant-popover-title {
  min-width: 70px;
  margin-bottom: 8px;
  background-color: white;
  border-bottom: 0.5px solid rgb(206, 206, 206) !important;
  color: #4a4949;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 5px 5px 0 0;
}

//price details [checkout form styles ]
.price-details-container {
  width: 100%;
  height: auto;
  border: 0.5px solid rgb(112, 111, 111);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  .single-item {
    font-size: 16px;
    font-weight: 900;
    padding: 5px 10px;
  }
  .saving {
    color: red;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 8px;
  }
  .total {
    border: 0.5px solid rgb(112, 111, 111);
    padding: 10px 0px;
    margin-top: 10px;
    text-align: center;
    background-color: #172b4d;
    color: #fff;
    span {
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .delivery-time {
    display: flex;
    flex-direction: column;
    padding: 5px;
    text-align: center;
    span {
      color: #388e3c;
      font-weight: 700;
      font-size: 17px;
    }
  }
  .price-header {
    background-color: rgb(2, 2, 69);
    color: #fff;
    padding: 10px;
    text-align: start;
  }
}

//categoryList css
.category-container {
  display: flex;
  background-color: white;
  text-transform: capitalize;
  padding: 5px 10px;
  .category-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #bababa;
    text-transform: capitalize;
    margin-right: 15px;
    padding: 5px 10px;
    border-radius: 6px;
    width: auto;
    h3 {
      font-size: 15px;
      text-align: center;
      padding: 0px 5px;
    }
    .img-icon {
      height: 25px;
      width: 25px;
    }
  }
}
