/* //static code style */
.card-list {
    max-height: 490px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    margin: 2px;
}

.date {
    font-weight: bold;
    font-size: 10px;
    color: black;
}

.content {
    font-size: 14px;
}

.card {
    background-color: white;
    border-radius: 4px;
    /* padding:50px; */
    color:black;
    margin: 2px;
    /* text-decoration: none; */
    /* z-index: 0; */
    /* overflow: hidden; */
    border: 2px solid #f2f8f9;
    padding:8px;
    /* box-shadow: rgba(196, 194, 194, 0.3) 0px 1px 2px 0px, rgba(193, 193, 193, 0.3)0px 2px 6px 2px; */
}

.card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -1px;
    border: 1px solid #cccccc;
    background-color: white;
}

.card-list {
    scrollbar-width: none;
    scrollbar-color: #888 #f1f1f1;
    overflow: auto;
    height:inherit;
    width: 100%; 
    height: 370px; 
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}


.read {
    background-color: white;

    /* Background color for read notifications (gray) */
}

.unread {
    background-color: #ebebeb;

    /* Background color for unread notifications */
}