.first-item-container {
  display: flex;
  gap: 150px;
  align-items: center;
}
.ant-btn-primary:not(:disabled):hover {
  color: #fff;
  background-color: #4096ff;
}
.book-total-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10pxs;
}
.books {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 0px;
}

.second-item-container {
  margin-top: 0px;
}
.books-count-container {
  background-color: #172b4d;
  width: 43.75px;
  height: 43.75px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 20px;
}
.books-count {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #ffffff;
}
.ant-input-group .ant-input {
  padding: 10px;
  padding-left: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  color: #8e8e8e;
  outline: none;
}
.mainContainer {
  // padding: 7px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  border: none;
  width: 100px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #172b4d;
}

.anticon-search {
  width: 30px;
  height: 30px;
  padding-top: 8px;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  border: none;
}
.background-image {
  background-image: url(https://img.lovepik.com/free-template/bg/20200526/bg/a16eada84bf1c.png_list.jpg!/fw/431/clip/0x300a0a0);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 20px;
}

.text-container {
  text-align: center;
  h3 {
    color: #000;
    font-weight: 800;
    cursor: pointer;
  }
}
.nameCategory {
  color: white;
}
.cards-row {
  display: flex;
}
