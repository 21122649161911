.birthday-item {
  display: flex;
  margin: 2px 12px ;
}
.birthday-item-flex {
  display: flex;
}
.EmptyBD{
  text-align: center;
}
.birthday-items-img img {
  display: block;
}
.birthday-desc {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 5px 10px 0 20px;
  width: 500px;
}
.birthday-btn {
  margin-left: 10px;
}
.birthday-btn button {
  background: #b3a149;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  border: 0;
  padding: 25px 40px;
  font-size: 25px;
}
.birthday-desc h2 {
  font-weight: normal;
}

.birthday-desc p {
  color: #4172bb;
}

/* small screens */

@media (max-width: 1320px) {
  .birthday-btn button {
    padding: 15px 30px;
    font-size: 20px;
  }
  .birthday-desc h2 {
    font-size: 17px;
  }
}
@media (max-width: 1200px) {
  .birthday-btn button {
    font-size: 13px;
  }
  .birthday-desc h2 {
    font-size: 15px;
  }

  .birthday-desc p {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .birthday-item {
    flex-direction: column;
  }
  .birthday-btn button {
    padding: 10px 20px;
    margin-left: 0px;
  }
  .birthday-btn {
    margin: 10px 0 25px;
  }
  .birthday-desc h2 {
    font-size: 13px;
  }
  .birthday-desc p {
    font-size: 11px;
  }
}
