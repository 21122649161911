.address-container {
  margin-top: 20px;
  margin-left: 10px;
}
.address-header {
  display: flex;
  justify-content: space-between;
}
.address-cards-conatainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.address-card {
  padding: 10px;
}
.back-container {
  display: flex;
  gap: 20px;
}
.button {
  background-color: var(--dark-blue);
  color: white;
  border-radius: 5px;
}
