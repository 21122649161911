/* :where(.css-dev-only-do-not-override-acm2ia).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color:white;
  text-shadow: 0 0 0.25px currentcolor;
  font-size: 16px;
  background-color: #172b4d;
  padding: 5px;
  border-radius: 10px;
  width: auto;
} */
.ant-tabs {
  box-sizing: border-box;
  /* margin-top: 13px; */
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  display: flex;
}

.tabs-btn {
  padding: 0.7rem 2rem;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 8px;
  border: 0;
  background: #d2cfcf;
  color: #888686;
}

.tabs-btn-active {
  background: #172b4d;
  color: white;
}

.results-widget {
  width: 80%;
  /* padding: 20px 0 0 20px; */
}

.result-search {
  flex: 0.7;
  margin-left: 30px;
  background: #e7e0e0;
  border-radius: 15px;
  padding: 10px 20px;
}

.result-search input {
  margin-left: 30px;
}

.result-search input::placeholder {
  font-size: 12px;
}

.results-title {
  flex: 0.3;
}

.results-icon {
  margin-left: 30px;
  color: #bbb8b8;
}

.results-flex {
  display: flex;
  padding: 0;
  /* margin: 50px 0 10px; */
}

.results-flex-btns {
  flex: 0.3;
}

.results-flex-btns button {
  background: #d2cfcf;
  border-radius: 5px;
  padding: 10px 40px;
  border: 0;
  margin-bottom: 6px;
  color: #bbb8b8;
}

.results-flex-btns button:nth-child(1) {
  background: #ffffff;
  color: black;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.results-content {
  flex: 0.6;
  margin: 20px 50px 0 10px;
}

#bg-purple {
  background: #5a1b49;
  color: #ffe600;
}

#bg-bblue {
  background: #4458be;
}

#bg-gold {
  background: #889c37;
}

.result-content-item {
  padding: 10px 5px;
}

.result-circle {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  font-weight: 400;
}

.result-timer {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0;
}

.result-timerText small {
  font-size: 9px;
  color: #4458be;
}

.item-circle {
  height: 40px !important;
  width: 40px !important;
}

.result-timerImg {
  margin-left: 5px;
}

.results-grid {
  margin-top: 5px;
}

.result-content-item h3 {
  font-weight: 600;
}

.active-button {
  background-color: blue !important;
  color: white;
}

/* Add this to your stylesheet */
.active-tab {
  background-color: blue;
}



/* media queries */
@media (max-width: 1200px) {
  .results-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {

  .results-widget,
  .results-flex {
    width: 100%;
    flex-direction: column;
  }

  .result-search,
  .results-content,
  .results-icon {
    margin-left: 0px;
  }

  .result-search input {
    margin-left: 10px;
  }

  .results-title {
    margin-bottom: 10px;
  }

  .results-widget {
    padding: 10px 0 0;
  }

  /* .results-grid, */

  .result-grid,
  #grid1 {
    grid-template-columns: repeat(1, 1fr);
  }
}