.checkout-container {
  background-color: #fff;
  .checkout-header {
    padding-bottom: 10px;
  }
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-left: 20px;
  }
  .address-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px;
    border: 0.5px solid rgb(112, 111, 111);
    cursor: pointer;
    border-radius: 5px;
  }
  .add-address {
    width: 100%;
    height: 150px;

    overflow: scroll;
    padding: 10px;
    border: 0.5px solid rgb(112, 111, 111);
    border-radius: 5px;
  }
  .add-address-text {
    span {
      color: #4a8bfa;
      margin-bottom: 5px;
    }
  }
  .price-details-container {
    width: 100%;
    height: 280px;
    border: 0.5px solid rgb(112, 111, 111);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    .single-item {
      font-size: 16px;
      font-weight: 900;
      padding: 5px 10px;
    }
    .saving {
      color: red;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding: 8px;
    }
    .total {
      border: 0.5px solid rgb(112, 111, 111);
      padding: 10px 0px;
      margin-top: 10px;
      text-align: center;
      background-color: #172b4d;
      color: #fff;
      span {
        padding: 10px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .delivery-time {
      display: flex;
      flex-direction: column;
      padding: 5px;
      text-align: center;
      span {
        color: #388e3c;
        font-weight: 700;
        font-size: 17px;
      }
    }
  }
  .price-header {
    background-color: rgb(2, 2, 69);
    color: #fff;
    padding: 10px;
    text-align: start;
  }
  .button-div {
    margin-top: 5px;
  }
}
