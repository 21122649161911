// .main-bg {
//   .culture-grids {
//     margin: 10px 10px 0;
//   }
//   .header {
//     display: flex;
//     // gap: 300px;
//     justify-content: space-between;
//     padding: 10px;
//   }
//   .culture-grid-item {
//     padding: 10px;
//     border-radius: 5px;
//     // width: 550px;
//     margin-bottom: 10px;

//     span {
//       display: block;
//     }
//     .desctription {
//       font-size: 12px;
//     }
//     .title {
//       font-size: 20px;
//       font-weight: 700;
//     }
//   }
//   .main-cultural-container {
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//   }

//   .culture-item {
//     display: flex;
//     justify-content: space-between;
//   }
//   .cancel-button {
//     background-color: red;
//     color: #fff;
//   }
//   .cancel-button:hover {
//     background-color: red;
//     color: #fff;
//   }
//   .submit-button {
//     background-color: #172b4d;
//     color: #fff;
//   }
//   .submit-button:hover {
//     background-color: #172b4d;
//     color: #fff;
//   }

//   #culture > h3 {
//     font-size: 25px;
//     margin-left: 10px;
//     padding-top: 8px;
//   }

//   .culture-text {
//     margin-left: 20px;
//   }

//   .culture-text p {
//     margin-top: 15px;
//   }

//   .culture-img img {
//     width: 70px;
//   }
//   .culturalActivicty-modal {
//     .datePicker {
//       width: 560px;
//     }
//     .ant-calendar-picker-input.ant-input {
//       border-radius: 0px;
//       width: 500px;
//     }
//   }

//   /* small screens */

//   @media (max-width: 768px) {
//     #culture {
//       height: unset;
//       padding-bottom: 30px;
//     }
//   }

//   @media (max-width: 500px) {
//     .culture-grids {
//       grid-template-columns: repeat(1, 1fr);
//     }
//   }
// }
.main-bg {
  background-color: #f5f5f5; /* Set the background color of the section */
  padding: 10px;

  .header {
    text-align: start;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 20px;
  }
.save-button{
  min-width: 100px;
}
.culture-text{
  line-height: 25px;
}
  .culture-grids {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute cards evenly in a row */
  }

  .culture-grid-item {
    flex-basis: calc(50% - 20px); /* Set card width to 50% minus some spacing */
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    position: relative;
    top: 0;
  }
  .cancel_button {
    background-color: #ff0000 !important;
    color: white;
    margin-top: 20px;
  }

  .title {
    font-weight: bolder;
    font-size: 17px;
    cursor: pointer;
  }
  .register-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #172b4d;
    color: #fff;
  }
  .save_button {
    background-color: #172b4d;
    color: #fff;
  }
  .save_button:hover {
    background-color: #172b4d;
    color: #fff;
    margin-right: 10px;
  }
  // .register-btn :hover {
  //   position: absolute;
  //   bottom: 10px;
  //   right: 10px;
  //   background-color: #172b4d;
  //   color: #fff;
  // }
  .desctription {
    display: block;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
  }
}
