.library-wrapper {
  .header-container {
    margin-top: 10px;
    display: flex;

    flex-direction: column;
  }
  .mainContainer {
    // padding: 10px;
  }
  .mheader {
    margin: 0px 0 15px 0;
    font-size: 18px;
  }

  .radioparent {
    border-bottom: 1px dashed var(--border);
    margin-bottom: 10px;
    .mainradio {
      .ant-radio-button-wrapper {
        width: 150px;
        text-align: center;
        background: var(--white-font);
        border: none !important;
        border-radius: 5px 5px 0 0;
        margin: 0 3px 0 0;
        &::before {
          border: none !important;
          width: 0;
        }
      }
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ) {
        background: var(--blue-new);
        border: none !important;
        border-radius: 5px 5px 0 0 !important;
      }
    }
  }
}
