 /* .ant-popover .ant-popover-inner { */
 .schoolNameContainer.ant-popover .ant-popover-inner {
     background-color: #ffffff;
     background-clip: padding-box;
     border-radius: 5px;
     box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
     padding: 0px;
     width: 400px;
     height: 550px;
     margin-right: -5px;
     margin-top: 5px;
 }

 .studentprofile-img {
     width: 55px;
     height: 40px;
     border-radius: 50%;
 }

 .schoolNameContainer {
     background-color: #fff;
     width: 100%;
     height: 50px;
     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
     position: fixed;
     top: 0;
     left: 110px;
     z-index: 999;
     border-radius: 0 10px 10px 0;
 }

 /* .last_icons {
     margin-top: 10px;
 } */

 :where(.css-dev-only-do-not-override-acm2ia).ant-popover .ant-popover-title {
     min-width: 177px;
     margin-bottom: 8px;
     background-color: white;
     border-bottom: 0.5px solid rgb(206, 206, 206);
     color: #4a4949;
     padding: 10px;
     font-size: 15px;
     font-weight: 500;
     border-radius: 5px 5px 0 0;
 }

 /* .ant-popover.ant-popover-title {
     background-color: #172b4d;
     color: #fff;
 } */
 .notification_number {
     background-color: red;
     width: 15px;
     height: 15px;
     border-radius: 50%;
     color: #fff;
     position: absolute;
     top: 0px;
     right: 2px;
     font-size: 8px;
     display: flex;
     align-items: center;
     text-align: center;
     justify-content: center;

 }

 .notificationTooltip {
     z-index: 999;
     margin-right: 10px;
     position: relative;
     margin-top: -0px;

 }

 .ant-popover .ant-popover-inner {
     background-color: #ffffff;
     background-clip: padding-box;
     border-radius: 5px;
     box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
     /* padding: 0px; */
     width: 350px;
     height: 450px;
     padding: 10px;
 }



 /* Thin scrollbar */
 .notificationTooltip::-webkit-scrollbar {
     /* margin-left: 10px; */
     width: 5px;
 }

 /* Track */
 .notificationTooltip::-webkit-scrollbar-track {
     background-color: #f1f1f1;

 }

 /* Thumb */
 .notificationTooltip::-webkit-scrollbar-thumb {
     background-color: #888;
     border-radius: 3px;
 }

 /* Thumb on hover */
 .notificationTooltip::-webkit-scrollbar-thumb:hover {
     background-color: #555;
 }

 /* Thin scrollbar for Firefox */
 .notificationTooltip {
     scrollbar-width: thin;
     scrollbar-color: #888 #f1f1f1;
 }

 /* Track for Firefox */
 .notificationTooltip::-moz-scrollbar-track {
     background-color: #f1f1f1;
 }

 /* Thumb for Firefox */
 .notificationTooltip::-moz-scrollbar-thumb {
     background-color: #888;
     border-radius: 3px;
 }

 /* Thumb on hover for Firefox */
 .notificationTooltip::-moz-scrollbar-thumb:hover {
     background-color: #555;
 }

 @media (max-width: 1025px) {}

 @media (max-width: 768px) {
     .schoolNameContainer {
         background-color: #fff;
         width: 100%;
         height: 45px;
         top: 0;
         left: 0px;
     }
 }