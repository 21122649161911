.heading-container {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  align-items: center;
  font-family: 'Poppins';
}

.heading-container h4 {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.date-paragraph {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: #172b4d;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
}

.ant-table-wrapper tfoot>tr>td {
  position: relative;
  padding: 1px 1px;
  overflow-wrap: break-word;
}

.radio-btn {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  content: '';
  display: inline-block;
  visibility: visible;
  border: none;
  margin-right: 5px;
}

.complete {
  background-color: #755C02;
}

.todo {
  background-color: #B89BF6
}

.inProgress {
  background-color: #D8A904
}